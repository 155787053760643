.lang{
    h1{
        color: #EC722E;
        border-bottom: 1px solid #EC722E;
        padding: 15px;
    }
    .table_lang{
        font-size: 16px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        .lang_row{
            padding: 10px 50px;
            display: flex;
            align-items: center;
            gap: 10px;
            border-bottom: 1px #E9E6E6 solid;
            background-color: #333849;
            .id{
                width: 50px;
                height: 100%;
            }
            .key{
                width: 100px;
                height: 100%;
            }
            .active{
                width: fit-content;
                height: 100%;
                padding: 1px 5px;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                cursor: pointer;
            }
            .delete_wrapper{
                flex: 1 0 auto;
                display: flex;
                justify-content: flex-end;
                cursor: pointer;
                color: red;
            }
            &:first-child{
                background-color: #20232E;
                .active{
                    background: none;
                    // color: #000;
                }
            }
        }
    }
    .error_text{
        color: red;
        width: 100%;
        text-align: center;
        margin-top: 15px;
    }
    .terms_text{
        display: flex;
        gap: 3px;
        flex-wrap: wrap;
        padding: 15px;
        span{
            font-size: 10px;
            padding: 0px 2px;
            color: red;
            border: 1px red solid;
        }
    }
    .add_lang{
        display: flex;
        justify-content: center;
        background-color: #20232E;
        padding: 10px 0;
        gap: 15px;
        input{
            width: 200px;
        }
        .button{
            margin: 0;
        }
    }
}
.arrows_block{
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    gap: 15px;
    flex-direction: column;
    .arrow_top, .arrow_bottom{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        cursor: pointer;
        color: #fff;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #EC722E;
    }
}
.modal_wrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);
    padding-left: 230px;

    .modal{
        background-color: #333849;
        margin-bottom: 20px;
        border-radius: 10px;
        padding: 20px;
        box-shadow: 0px 0px 30px -7px #000;

        h2{
            text-align: center;
        }

        .content{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            padding-top: 20px;
            
            .image_wrapper{
                width: 200px;
                height: 150px;
                box-shadow: 1px 1px 13px -7px #000;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        
            .image_items_wrapper{
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 20px;
                margin-top: 17px;
        
                .image_item_wrapper{
                    display: flex;
                    flex-direction: column;
                    gap: 0px;
        
                    .image_wrapper{
                        position: relative;
                    }
        
                    .header{
                        display: flex;
                        gap: 7px;
                        justify-content: center;
        
                        .lang{
                            text-transform: uppercase;
                        }
                        .default_wrapper{
                            display: flex;
                            gap: 7px;
        
                            input{
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
        
                    .change_input{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        cursor: pointer;
                    }
                }
            }
        }
        
        .footer{
            display: flex;
            gap: 15px;
            justify-content: center;
        }
    }
}
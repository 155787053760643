@import '../../../index.scss';
.users{
    width: 100%;
    h1{
        color: #EC722E;
        border-bottom: 1px solid #EC722E;
        padding: 15px;
    }
    .content{
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        table{
            width: 100%;
            // background-color: #E9E6E6;
            border-spacing: 2px;
            font-size: 14px;
            tr{
                border-bottom: 1px solid #000;
                background-color: #20232E;
                td{
                    padding: 5px 10px;
                    .tools_icon{
                        cursor: pointer;
                        float: right;
                        svg{
                            pointer-events: none;
                            height: 20px;
                            width: 25px;
                            transition: .3s;
                            &:hover{
                                path{
                                    transition: .3s;
                                    fill: #EC722E !important;
                                }
                            }
                        }
                    }
                    .terms_id{
                        color: green;
                    }
                }
                .user_id{
                    color: green !important;
                }
                .t_tools{
                    display: flex;
                    // display: none;
                    justify-content: center;
                    gap: 15px;
                    .edit{
                        position: relative;
                        width: 25px;
                        height: 25px;
                        cursor: pointer;
                        &::after{
                            position: absolute;
                            content: "\270E";
                            width: 25px;
                            height: 25px;
                        }
                    }
                    .delete{
                        position: relative;
                        width: 25px;
                        height: 25px;
                        cursor: pointer;
                        &::after{
                            position: absolute;
                            content: "\2716";
                            width: 25px;
                            height: 25px;
                        }
                    }
                    .add_element{
                        position: relative;
                        width: 25px;
                        height: 25px;
                        cursor: pointer;
                        &::after{
                            position: absolute;
                            content: "\271A";
                            width: 25px;
                            height: 25px;
                        }
                    }
                }
            }
        }
    }
}
@import '../../../index.scss';
.terms{
    width: 100%;
    padding: 20px;
    padding-top: 70px;
    .filters_wrapper{
        width: 100%;
        height: 50px;
        background-color: #EC722E;
        position: fixed;
        top: 0;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        .lang_block{
            @include lang_block;
            position: absolute;
            right: 10px;
            top: 0px;
            .lang_item{
                background-color: #fff;
                color: #EC722E;
            }
        }
        .filters{
            display: flex;
            gap: 20px;
            .filter_row{
                display: flex;
                align-items: center;
                gap: 10px;
                select{
                    padding: 3px 5px;
                }
            }
        }
        
    }
    .content{
        width: 100%;
        table{
            width: 100%;
            // background-color: #E9E6E6;
            border-spacing: 2px;
            font-size: 14px;
            tr{
                border-bottom: 1px solid #000;
                background-color: #20232E;
                &:first-child{
                    
                }
                td{
                    padding: 5px 10px;  
                    .date{
                        white-space: nowrap;
                    }
                    .lang{
                        text-transform: uppercase;
                    }
                    .value{
                        // position: relative;
                        svg{
                            height: 20px;
                            cursor: pointer;
                            // position: absolute;
                            // top: 50%;
                            // right: 0;
                            float: right;
                            transition: .3s;
                            &:hover{
                                stroke: #EC722E !important;
                            }
                        }
                    }
                    .terms_id{
                        color: green;
                    }
                }
                .t_tools{
                    display: flex;
                    // display: none;
                    justify-content: center;
                    gap: 15px;
                    .edit{
                        position: relative;
                        width: 25px;
                        height: 25px;
                        cursor: pointer;
                        &::after{
                            position: absolute;
                            content: "\270E";
                            width: 25px;
                            height: 25px;
                        }
                    }
                    .delete{
                        position: relative;
                        width: 25px;
                        height: 25px;
                        cursor: pointer;
                        &::after{
                            position: absolute;
                            content: "\2716";
                            width: 25px;
                            height: 25px;
                        }
                    }
                    .add_element{
                        position: relative;
                        width: 25px;
                        height: 25px;
                        cursor: pointer;
                        &::after{
                            position: absolute;
                            content: "\271A";
                            width: 25px;
                            height: 25px;
                        }
                    }
                }
            }
        }
    }
}
@import '../../../index.scss';

.wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        border-bottom: 1px #ccc solid;
        padding-right: 20px;
        .left{
            display: flex;
            align-items: center;
            .header_item{
                display: flex;
                gap: 7px;
                .title{
                    color: #EC722E;
                    font-size: 16px;
                }
                .value{
                    color: green;
                    font-size: 16px;
                }
            }
        }
        .tools{
            @include tools;
        }
    }
    .content{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        margin: 17px 0;

        .header{
            display: flex;
            gap: 3px;
            justify-content: center;

            .language{
                text-transform: uppercase;
            }
        }
        .color{
            color: rgb(151, 255, 151);
        }

        .image_item_wrapper{
            display: flex;
            flex-direction: column;

            .image_wrapper{
                width: 200px;
                height: 150px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                box-shadow: 1px 1px 13px -7px #000;
            }
        }
    }
}
@import '../../../index';
@include auth_page;

.auth_wrapper{
    backdrop-filter: blur(8px);
    background: none;
    .auth{
        h1{
            text-align: center;
        }
        // border: 1px #fff solid;
        background-color: #333849;
        padding: 0;
        form{
            padding: 20px 20px 0 20px !important;
            .form_row{
                
                    width: 500px !important;
             
                .per_title{
                    text-align: center;
                    font-size: 16px;
                }
                .per_row{
                    .title{
                        font-size: 14px;
                        // color: #EC722E;
                    }
                    .per_table{
                        // border: 1px #EC722E solid;
                        padding: 7px;
                        display: flex;
                        // justify-content: space-evenly;
                        gap: 5px;
                        .per_item{
                            width: fit-content;
                            border: 1px rgb(88, 145, 88) solid;
                            padding: 5px;
                            border-radius: 4px;
                            cursor: pointer;
                            user-select: none;
                            transition: .3s;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                        }
                        .active{
                            background-color: green;
                            color: #FFF;
                        }
                    }
                }
            }
            
        }
    }
}

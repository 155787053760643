html{
    // &::-webkit-scrollbar {
    //     display: none;
    // }
    scrollbar-width: thin;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
a{
    text-decoration: none;
    color: #000;
}
body{
    font-size: 12px;
    font-family: 'Noto Sans';
    font-weight: 400;
    overflow-x: hidden;
    color: #000;
    font-family: 'Poppins', sans-serif;
    color: #FFF;
    background-color: #14161D;
}
h1{
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
}
table{
    border-collapse: separate;
}

.loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

input{
    border-radius: 2px;
    // border: 1px #EC722E solid;
    border: none;
    outline: none;
    padding: 15px 5px;
    height: 40px;
    background-color: #424A61;
    color: #FFF;
}
input[type='file']{
    border: none !important;
}

.modal_wrapper{
    z-index: 3;
    width: 100vw;
    height: 100vh;
    margin-left: 130px;
    left: 0;
    top: 0;
    position: fixed;
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
    .modal{
        min-width: 500px;
        width: 500px;
        max-height: 80vh;
        overflow-y: scroll;
        padding: 20px 15px;
        // border: 1px #fff solid;
        background-color: #333849;
        &::-webkit-scrollbar {
            display: none;
        }
        scrollbar-width: none;
        box-shadow: 0px 0px 30px -7px #000;
        h2{
            text-align: center;
            color: #EC722E;
        }
        .modal_form{
            display: flex;
            flex-direction: column;
            position: relative;
            gap: 20px;
            .form_row{
                display: flex;
                flex-direction: column;
                input, select{
                    height: 30px;
                    width: 100%;
                    padding: 0px 8px;
                    background-color: #424A61;
                    border: none;
                    color: #FFF;
                }
                select[disabled]{
                    background-color: #424A61;
                    border: none;
                }
                input[type="file"]{
                    background: none;
                }
                textarea{
                    outline: none;
                    // border: 1px #EC722E solid;
                    padding: 5px;
                    background-color: #424A61;
                    border: none;
                    color: #FFF;
                }
                label{
                    font-size: 15px;
                    color: #EC722E;
                    span{
                        color: green;
                    }
                }
                .img_wrapper{
                    height: 200px;
                    width: 100%;
                }
            }
            .form_group{
                display: flex;
                flex-direction: column;
                gap: 15px;
                .form_row_group{
                    display: flex;
                    gap: 15px;
                    .left{
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        input, select{
                            height: 30px;
                            width: 100%;
                            padding: 0px 8px;
                            background-color: #424A61;
                            color: #fff;
                            border: none;
                        }
                        label{
                            font-size: 15px;
                            color: #EC722E;
                        }
                    }
                    .right{
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        input, select{
                            height: 30px;
                            width: 100%;
                            padding: 0px 8px;
                            background-color: #424A61;
                            color: #fff;
                            border: none;
                        }
                        label{
                            font-size: 15px;
                            color: #EC722E;
                        }
                    }
                }
            }
            .button{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                outline: none;
                border: none;
                background: none;
                border: 1px #FFF solid;
                color: #FFF;
                transition: .3s;
                &:hover{
                    color: #FFF;
                    background-color: #EC722E;
                }
            }
            .error_text{
                font-size: 13px;
                color: red;
            }
        }
    }
}
.button{
    font-size: 15px;
    padding: 7px 10px;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
    background-color: #EC722E;
    user-select: none;
}
.add_module_button{
    font-size: 12px;
    padding: 4px 7px;
    width: fit-content;
    margin: 0 auto;
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
    background-color: #EC722E;
    user-select: none;
    position: relative;
    padding-right: 15px;
    span{
        font-size: 15px;
        margin-left: 5px;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
    }
}

@mixin tools{
    display: flex;
    gap: 10px;
    .edit_icon, .delete_icon{
        position: relative;
        cursor: pointer;
        display: block;
        width: 20px;
        height: 20px;
        svg{
            width: 20px;
            height: 20px;
        }
        &::after{
            position: absolute;
            content: "";
            font-size: 20px;
        }
    }
    .delete_icon{
        
    }
    .add_icon{
        position: relative;
        width: 25px;
        height: 25px;
        cursor: pointer;
        &::after{
            position: absolute;
            content: "\271A";
            width: 25px;
            height: 25px;
        }
    }
}



@mixin auth_page{
    .auth_wrapper{
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
        .auth{
            background-color: #20232E;
            padding-bottom: 30px;
            // border: 1px #fff solid;
            box-shadow: 0px 0px 30px -7px #000;
            display: flex;
            flex-direction: column;
            border-radius: 8px;
            h1{
                border-bottom: 1px solid #E0E3E4;
                padding: 0 15px;
                font-size: 20px;
            }
            .auth_form{
                display: flex;
                flex-direction: column;
                padding: 30px 50px 0 50px;
                align-items: center;
                gap: 20px;
                .confirm_title{
                    background-color: #FF69B4;
                    padding: 16px 20px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    border-radius: 4px;
                    position: relative;
                    &::after{
                        position: absolute;
                        content: '';
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 10px;
                        border-radius: 4px 0px 0px 4px;
                        opacity: 0.30000001192092896;
                        background-color: #fff;
                    }
                    .text{
                        font-size: 14px;
                    }
                }
                .form_row{
                    width: 300px;
                    display: flex;
                    flex-direction: column;
                    input{
                        // height: 30px;
                    }
                    label{
                        font-size: 15px;
                    }
                }
                button{
                    cursor: pointer;
                    height: 45px;
                    font-size: 18px;
                    border: none;
                    background-color: #EC722E;
                    outline: none;
                    color: #fff;
                    border-radius: 4px;
                    max-width: 300px;
                    width: 100%;
                }
                .error_text{
                    font-size: 13px;
                    color: red;
                }
            }
            .notes{
                text-align: center;
                a{
                    font-size: 16px;
                    color: #3E77AA;
                }
            }
        }
    }
}

@mixin lang_block{
    display: flex;
    gap: 15px;
    padding: 15px;
    user-select: none;
    .title{
        font-size: 16px;
    }
    .lang_item{
        cursor: pointer;
        border-radius: 50%;
        background-color: #EC722E;
        padding: 7px;
        font-size: 10px;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-transform: uppercase;
    }
    .value{
        background-color: rgb(189, 174, 174);
        padding: 7px;
    }
}

.colored-toast.swal2-icon-success {
    background-color: #EC722E !important;
    .swal2-title{
        color: #FFF;
    }
}
.colored-toast.swal2-icon-error {
    background-color: #f27474  !important;
  }
  
  .colored-toast.swal2-icon-warning {
    background-color: #f8bb86 !important;
  }
  
  .colored-toast.swal2-icon-info {
    background-color: #3fc3ee !important;
  }
  
  .colored-toast.swal2-icon-question {
    background-color: #87adbd !important;
  }
  
  .colored-toast .swal2-title {
    color: white;
  }
  
  .colored-toast .swal2-close {
    color: white;
  }
  
  .colored-toast .swal2-html-container {
    color: white;
  }
.swal2-confirm{
    background-color: #EC722E !important;
}
.swal2-title{
    font-size: 20px !important;
}
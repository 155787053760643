.preloader{
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);
    img{
        width: 50px;
        height: 50px;
        animation: loading_rotate 2s infinite linear;
    }
}
@keyframes loading_rotate{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
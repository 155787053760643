.button{
    max-height: 51px;
    width: 100%;
    background-color: #EC722E;
    color: #FFF;
    font-size: 16px;
    padding: 12px 25px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px transparent solid;
    transition: .2s;
    &:hover{
        background: rgb(235, 125, 65);
    }
    svg{
        height: 40px !important;
        width: 40px !important;
        animation: rotate 2s linear infinite forwards;
    }
}

.close{
    background: none;
    border: 1px #EC722E solid;
    color: #EC722E;
    &:hover{
        background: #35363B;
    }
}

@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.main{
    width: 100%;
    display: flex;
    .left_menu{
        background-color: #20232E;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        left: 0;
        top: 0;
        width: 230px;
        z-index: 10;
        .menu{
            display: flex;
            flex-direction: column;
            .menu_item{
                padding: 15px;
                border-bottom: 1px solid rgb(197, 196, 196);
                display: flex;
                align-items: center;
                gap: 15px;
                a{
                    color: #fff;
                    font-size: 18px;
                    text-decoration: none;
                }
            }
        }
        .bottom_menu{
            .user_menu{
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 5px;
                margin-bottom: 30px;
                p{
                    font-size: 14px;
                    color: #FFF;
                    span{
                        color: rgb(151, 255, 151);
                    }
                }
            }
            .menu_item{
                padding: 15px;
                border-top: 1px solid rgb(197, 196, 196);
                a, p{
                    color: #fff;
                    font-size: 18px;
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }
    .content{
        width: 100%;
        margin-left: 230px;
        position: relative;
        background-color: #14161D;
    }
}
.module{
    width: 100%;
    position: relative;
    h1{
        color: #EC722E;
        border-bottom: 1px solid #EC722E;
        padding: 15px;
    }
    .content{
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        form{
            background-color: #20232E;
            padding: 20px;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            .inputs{
                display: flex;
                gap: 20px;
                .input_wrapper{
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}
@import '../../../index.scss';

.wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        border-bottom: 1px #ccc solid;
        padding-right: 20px;
        .left{
            display: flex;
            align-items: center;
            gap: 10px;
            .header_item{
                display: flex;
                gap: 7px;
                .title{
                    color: #EC722E;
                    font-size: 16px;
                }
                .value{
                    color: green;
                    font-size: 16px;
                }
            }
            .lang_block{
                padding: 0 !important;
                @include lang_block;
            }
        }
        .tools{
            @include tools;
        }
    }
    .content{
        padding: 20px;
        width: 100%;
    }
}
.modal_wrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(15px);
    top: 0;
    left: 0;
    .modal{
        background-color: #1E1E21;
        padding: 20px;
        width: 500px;
        .header{
            display: flex;
            justify-content: center;
            padding: 0;
            h1{
                color: #EC722E;
                font-size: 28px;
                border: none;
                padding: 0;
            }
        }
        .content{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .lang_bar{
                display: flex;
                gap: 5px;
                .lang{
                    background-color: #252529;
                    border: 1px solid #35363B;
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    padding: 3px 7px;
                    cursor: pointer;
                    svg{
                        stroke: #35363B;
                        transition: .3s;
                    }
                }
                .active{
                    border: 1px solid #EC722E;
                }
            }
            .input{
                width: 100%;
                background-color: #252529;
                border: 1px solid #35363B;
            }
            textarea{
                width: 100%;
                background-color: #252529;
                outline: none;
                border: none;
                color: #fff;
                padding: 5px;
                border: 1px solid #35363B
            }
            .buttons{
                display: flex;
                gap: 20px;
                .button{
                    width: 100%;
                }
            }
        }
    }
}
@import '../../../index.scss';
.module{
    width: 100%;
    position: relative;
    h1{
        color: #EC722E;
        border-bottom: 1px solid #EC722E;
        padding: 15px;
    }
    .header{
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        padding: 20px 0 0 20px;
        .page_item{
            padding: 10px;
            padding: 10px 20px;
            background-color: #333849;
            // background-color: #EC722E;
            color: #000;
            // border: 1px #EC722E solid;
            border-radius: 5px;
            cursor: pointer;
            font-size: 18px;
            transition: .3s;
            color: #FFF;
            &:hover{
                color: #FFF;
                background-color: #EC722E;
            }
        }
    }
    .active{
        color: #FFF;
        background-color: #EC722E;
    }
    .content{
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        .buttons{
            display: flex;
            gap: 20px;
        }
    }
}
@import '../../../index.scss';

.item{
    background-color: #20232E;
    // background-color: #556dc2;
    border: 1px #333849 solid;
    padding: 0px 20px 10px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 20px;
    header{
        padding: 10px;
        display: flex;
        justify-content: center;
        position: relative;

        .marker_wrapper{
            display: flex;
            align-items: center;
            gap: 7px;
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0.4;
            transition: .3s;

            .marker_title{
                color: #FFF;
                font-size: 13px;
            }

            input{
                height: 20px;
                padding: 5px;
            }

            .save{
                font-size: 13px;
                border: 1px #CCC solid;
                border-radius: 3px;
                color: #CCC;
                padding: 0px 5px;
                cursor: pointer;
                transition: .3s;

                &:hover{
                    color: #FFF;
                    border-color: #FFF;
                    background: #2b2f3d;
                }
            }

            .preloader_wrapper{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 45px;
                height: 25px;
                animation: loading_rotate 2s infinite linear;
                
                svg{
                    width: 25px;
                    height: 25px;
                    position: absolute;
                }
            }
        }

        span{
            font-size: 20px;
            color: #EC722E;
        }

        .tools{
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            @include tools;
        }
    }
}

@keyframes loading_rotate{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
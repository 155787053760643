@import '../../../index.scss';

.item{
    background-color: #20232E;
    padding: 20px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 20px;
    box-shadow: 1px 1px 13px -7px #000;
    margin: 15px auto;
    header{
        padding: 10px;
        display: flex;
        justify-content: center;
        position: relative;

        .marker_wrapper{
            display: flex;
            align-items: center;
            gap: 7px;
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0.4;
            transition: .3s;

            .marker_title{
                color: #FFF;
                font-size: 13px;
            }

            input{
                height: 20px;
                padding: 5px;
            }
        }

        span{
            font-size: 20px;
            color: #EC722E;
        }
        .tools{
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            @include tools;
        }
    }
}
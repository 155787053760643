@import '../../../index.scss';

.item{
    background-color: #333849;
    padding: 0px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 20px;
    position: relative;
    .item_title{
        position: absolute;
        left: 20px;
        top: 20px;
        color: #EC722E;
        font-weight: 600;
    }
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        padding-top: 20px;
        input[type="file"]{
            background: none;
            width: fit-content;
        }
        textarea, input{
            outline: none;
            padding: 5px;
            border: none;
            background-color: #424A61;
            color: #FFF;
            width: 50%;
        }
        .image_wrapper{
            width: 200px;
            height: 150px;
            // background-color: #424A61;
            box-shadow: 1px 1px 13px -7px #000;
        }
        .text_view_wrapper{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            span{
                text-transform: uppercase;
            }
        }
        .image_items_wrapper{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 20px;
            margin-top: 17px;

            .image_item_wrapper{
                display: flex;
                flex-direction: column;
                gap: 0px;

                .image_wrapper{
                    position: relative;
                }

                .header{
                    display: flex;
                    gap: 7px;
                    justify-content: center;

                    .lang{
                        text-transform: uppercase;
                    }
                    .default_wrapper{
                        display: flex;
                        gap: 7px;

                        input{
                            width: 15px;
                            height: 15px;
                        }
                    }
                }

                .change_input{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    cursor: pointer;
                }
            }
        }
    }
}
@import '../../../index.scss';
.add_post{
    width: 100%;
    position: relative;
    h1{
        color: #EC722E;
        border-bottom: 1px solid #EC722E;
        padding: 15px;
    }
    .content{
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
}